import _ from "lodash";
import {isSameRouteWithQueryParams} from "@/plugins/functions";
import FilterComponent from "@/components/FilterComponent.vue";
import {mapGetters, mapActions} from "vuex";
import PaginationView from "@/plugins/mixins/PaginationView";

let FilterView = {
    components: {
        FilterComponent
    },

    mixins: [
        PaginationView
    ],

    data() {
        return {
            userFilters: [],
            filterData: [],
            filterValues: {},

            options: {
                sortBy: ["name"],
                sortDesc: true,
                page: 1,
                pageCount: 0,
                perPage: 25,
            },
        }
    },

    computed: {
        ...mapGetters(['itemsPerPage']),

        currentFilter() {
            let filterId = parseInt(this.$route.query.filterId);

            if (filterId && this.userFilters) {
                let currentFilter = _.find(this.userFilters, (item) => {
                    return item.id === filterId;
                })

                if (currentFilter) {
                    return currentFilter;
                }
            }

            return null;
        },
    },

    watch: {
        '$route.query': {
            immediate: true,
            handler(newVal) {
                console.log('watching route query')
                this.processQuery();
                this.init()
            }
        },
    },

    beforeMount() {
        this.processQuery();
    },

    methods: {
        ...mapActions(['setSettings']),

        processQuery() {
            if (
                this.$route.query
                && this.$route.query.filter
                && _.isPlainObject(this.$route.query.filter)
            ) {
                this.$set(this, 'filterValues', this.$route.query.filter);
            } else {
                this.$set(this, 'filterValues', {});
            }

            let itemsPerPage = parseInt(this.$route.query.itemsPerPage);
            if (itemsPerPage) {
                this.options.itemsPerPage = itemsPerPage;
                this.setSettings({
                    ...this.$auth.user().settings,
                    itemsPerPage: itemsPerPage
                })
            } else {
                this.options.itemsPerPage  = this.itemsPerPage;
            }

            let page = parseInt(this.$route.query.page);
            if (page) {
                this.options.page = page;
            }

            let sortBy = this.$route.query.sortBy;
            if (sortBy) {
                this.options.sortBy = [sortBy];
            }

            this.options.sortDesc = [this.$route?.query?.sortDir === 'true'];

            if(!!this.onProcessQueryDone && this.onProcessQueryDone instanceof 'function') {
                this.onProcessQueryDone();
            }
        },

        async getFilters() {
            this.loading = true;
            let p = null;

            // if method getFilterParams exist
            if (this.getFilterParams) {
                p = {
                    params: this.getFilterParams()
                };
            }

            await this.$http
                .get(`client/${this.entityName}/filter`, p)
                .then(res => {
                    this.filterData = res.body.filter;
                    this.userFilters = res.body.data.map((item) => {
                        return {
                            ...item,
                            url: {
                                path: this.baseUrl ? this.baseUrl : `/${this.entityName}`,
                                query: {
                                    filterId: item.id + '',
                                    filter: item.values
                                }
                            }
                        }
                    })
                })
                .catch(err => {
                    this.userFilters = []
                })
                .finally(end => {
                    this.loading = false
                })
        },

        submitForm(value) {
            let query = {
                ...this.$route.query,
                filter: value
            };

            if (this.currentFilter) {
                query.filterId = this.currentFilter.id;
            }

            this.$router.push({
                query
            });
        },

        filterSaved(data) {
            this.$router.push({
                query: {
                    filterId: data.id,
                    filter: data.values
                }
            });
        },

        filterDeleted() {
            this.$router.push({
                query: {}
            });
        },

        onPageChange(page) {
            this.$router.push({
                query: {
                    ...this.$route.query,
                    page: page
                }
            });
        },

        onPerPageChange(value) {
            this.$router.push({
                query: {
                    ...this.$route.query,
                    itemsPerPage: value
                }
            });
        },

        isSameRouteWithQueryParams(a, b) {
            return isSameRouteWithQueryParams(a, b)
        },
    }
}

export default FilterView;
